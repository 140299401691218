@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
}

@font-face {
  font-family: 'Joystix';
  src: url('../fonts/joystix monospace.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.joystix-font {
  font-family: 'Joystix', monospace;
}

.number-circle {
  font-family: 'Joystix', monospace;
}